import React,{useEffect,useState} from 'react'
import { Layout, Badge, Dropdown, Menu } from "antd"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link ,navigate} from "gatsby"
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles';
import {typography} from '@src/theme/theme'
import HeaderTab from '@src/components/common/HeaderTab'
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import { ReactComponent as Account } from '@src/assets/svgIcons/Account.svg'
import LoginModal from '@src/components/LoginModal'
import VerificationModal from '@src/components/VerificationModal'
import Avatar from '@material-ui/core/Avatar';
import trim from 'lodash/trim'
import LoginIconButton from '../common/LoginIconButton'
import TEAMLINKBlackLogo from '../../assets/icons/TeamlinkLogoBlack.svg'
import UserAvatarButton from '../../components/common/UserAvatarButton'
import MessageMenu from "../message/MessageMenu";
import random from 'lodash/random'
import BusinessMenu from '../common/BusinessMenu'

const { Header } = Layout

const ExploreHeader = (props)=> {
    const {
        uiStore,
        appStore,
        fullWidth = false
    } = props
    const {
        explorePageCurrentTab,
        setExplorePageCurrentTab,
        isExploreView,
        isCollectionView,
        isMessagesView,
        openLogin,
        setOpenLogin,
        checkLogin,
        setIsMeSettingOpen,
        switchMeSetting,
        isVerificationOpen,
        setIsVerificationOpen,
        previousPageTab,
        isMessagePanelVisible,
        setIsMessagePanelVisible
    } = uiStore
    //const [visible, setVisible] = useState(false);
    const {
        loggedInUser, heartBeatFlag
    } = appStore
    const data = useStaticQuery(graphql`
    query{
        file(relativePath: {eq: "TEAMLINK-logo.png"}) {
            childImageSharp {
                fixed(width:150) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    `)

    // console.log('loggedInUser',loggedInUser)
    const getCurrentPath = ()=>{
        let myPath = window.location.pathname
        return trim(myPath,`/`)
    }
    const [ heartBeat, setHeartBeat ] = React.useState({ numNewMessages: 0 })

    const tabs=[
        {
            label:'Explore',
            onClick:()=>{
                setExplorePageCurrentTab('explore')
                if(!['explore','collections'].includes(getCurrentPath().toLowerCase())){
                    navigate('/explore');
                }
                props.propertyStore.getMapProperties_()
            },
            isSelected:isExploreView
        },
        {
            label:'Collections',
            onClick:checkLogin(()=>{
                setExplorePageCurrentTab('collections')
                if(!['explore','collections'].includes(getCurrentPath().toLowerCase())){
                    navigate('/collections')
                }
            }),
            isSelected:isCollectionView
        },
        {
            label: 'Messages',
            onClick:checkLogin(()=>{
                setExplorePageCurrentTab('messages')
                setIsMessagePanelVisible(!isMessagePanelVisible)
            }),
            isSelected:isMessagesView,
            className:'ignore-class'
        },
    ]
    const styles = useStyles()

    useEffect(() => {
        if(Boolean(loggedInUser)===false){
            setExplorePageCurrentTab('explore')
        }
    }, [loggedInUser])

    React.useEffect(() => {
        let timeoutHandle;

        const timeoutFn = () => {            
            
            if (appStore.loggedInUser) {
                appStore.clientApi.heartBeat_().then((hb) => {
                    setHeartBeat({ numNewMessages: hb.numUnreadNoti + hb.numUnreadCs + hb.numUnreadMsg });
                    //console.log('>>>message count:', hb.numUnreadNoti + hb.numUnreadCs + hb.numUnreadMsg)

                    timeoutHandle = setTimeout(timeoutFn, 30000);
                });          
            } else {
                setHeartBeat({numNewMessages: 0});
            }  
        };

        timeoutFn();

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    }, [loggedInUser, heartBeatFlag]);

    
    return (
        <Header
            style={{
                zIndex:10,
                width: "100%",
                height:64,
                backgroundColor:"white",
                filter:'drop-shadow(0 -2px 10px rgba(0, 0, 0, 0.1))',
                position:'relative'
            }}
        >
            <div className={fullWidth?"":"fluid-container"} style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}> 
                <Link style={{...hBox('center')}}  to="/">
                    <img src={ TEAMLINKBlackLogo } style={{'height' : 28, 'width' : 'auto'}}/>
                    {/* <Img fixed={data.file.childImageSharp.fixed}/> */}
                </Link>
                <div style={{...hBox('center')}}>
                    {tabs.map((item, index)=>
                        index === 2 ? 
                        <Badge size={'small'} key={item.label} offset={[-42, 5]} count={heartBeat.numNewMessages}>
                            <HeaderTab {...item} key={item.label} style={{marginLeft:24,marginRight:48, marginTop: -2}} withBadge badgeCount={heartBeat.numNewMessages} />
                        </Badge>
                        :<HeaderTab {...item} key={item.label} style={{marginLeft:24,marginRight:24}}/>
                    )}
                    {loggedInUser?
                    <UserAvatarButton onClick={()=>switchMeSetting()} avatar={`${loggedInUser.avatar}?rand=${random(1000)}`} isLandingPage={false}/>
                    // <Avatar onClick={()=>setIsMeSettingOpen(true)} style={{fontSize:16,marginLeft:16}} className={styles.avatar} src={loggedInUser.avatar}>{!loggedInUser.avatar && loggedInUser.name}</Avatar>
                    :
                    <BusinessMenu onClientLogin={()=>setOpenLogin(true)} />
                    // <Button variant={'outlined'} color='primary' onClick={()=>setOpenLogin(true)}>
                    //     <Account height={12} width={12} fill={'black'} style={{marginRight:4}}/>
                    //     Login
                    // </Button>
                    }
                </div>
            </div>
            <LoginModal visible={openLogin} handleCancel={()=>setOpenLogin(false)}/>
            <VerificationModal visible={isVerificationOpen} handleCancel={()=>setIsVerificationOpen(false)} />
            <MessageMenu visible={isMessagePanelVisible} setVisible={(value)=>{
                if(value===false){
                    setExplorePageCurrentTab(previousPageTab)
                }
                setIsMessagePanelVisible(false)
            }}/>
        </Header>
    )
}

const useStyles = makeStyles(theme=>({
    avatar:{
        width: 42,
        height: 42,
        cursor:'pointer'
    }
}))

export default compose(
    inject('uiStore','appStore','propertyStore'),
    observer
)(ExploreHeader)
