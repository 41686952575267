import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {typography} from '@src/theme/theme'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import ButtonBase from '@material-ui/core/ButtonBase'
import { Badge } from 'antd';
// import cx from 'classnames'
import {motion} from 'framer-motion'

const HeaderTab = props=>{
    const {label,onClick,className,isSelected=false,style} = props
    const styles = useStyles()
    const theme = useTheme()
    return <ButtonBase onClick={onClick} className={className}>
        <div className={styles.base} style={style}>
            <motion.div 
                variants={{
                    selected:{color:'#000000'},
                    unselected:{color:theme.palette.grey[500]}
                }}
                initial={isSelected?'selected':'unselected'}
                animate={isSelected?'selected':'unselected'}
                className={styles.label}>
                {label}
            </motion.div>
            <motion.div 
                variants={{
                    selected:{opacity:1},
                    unselected:{opacity:0}
                }}
                initial={isSelected?'selected':'unselected'}
                animate={isSelected?'selected':'unselected'}
                className={styles.bar}
            />
        </div>
    </ButtonBase>
}

const useStyles = makeStyles(theme=>({
    base:{
        ...vBox('center','center'),
        position:'relative'
    },
    bar:{
        height:3,
        backgroundColor:theme.palette.clientPrimary.main,
        width:20,
        position:'absolute',
        transform:'translateY(15px)'
    },
    label:{
        ...typography.subtitle2,
        fontSize : 16
    },
    indicator:{
        width: 20,
        height: 20,
    },
}))
export default HeaderTab
